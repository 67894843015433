<template>
    <div>
        <h1>{{ state.object.data.branch_name }}</h1>
        <Details v-show="isCurrentView('details')" :data='state.object.data' />
        <Dashboard v-show="isCurrentView('dashboard')" :data='state.object.data' />
        <VolumeDiscountBranch :data="state.object.data" :branch_id="state.object.data.branch_id" v-show="isCurrentView('volDiscountBranch')" />
    </div>
</template>

<script>
import Details from '@/components/businessObjects/utils/Details';
import Dashboard from '@/components/businessObjects/branch/Dashboard';
import { store } from '@/store/BusinessObject.store';
import { branchStore } from './Branch.store';
import VolumeDiscountBranch from "@/components/businessObjects/branch/VolumeDiscountBranch";

export default {
    name: 'Container',
    data() {
        return {
            state: store.state,
            branchState: branchStore.state,
        }
    },
    props: ['id'],
    created() {
        store.initialize(this.branchState);
        this.load()
    },
    methods: {
        load: function() {
            if (this.id){
                store.load(this.id, 'dashboard')
                    .then(() => {
                        this.$appStore.setTitle([this.state.object.data.branch_name, this.state.titlePlural])
                        this.state.breadcrumbs = [
                            {
                                label: this.state.object.data.company_name,
                                slug: '/company/' + this.state.object.data.company_id
                            },
                            {
                                label: this.state.object.data.branch_name,
                                slug: '/branch/' + this.state.object.data.branch_id
                            }
                        ];
                    });
            }
        },
        isCurrentView(view) {
            return view === this.state.currentView;
        }
    },
    computed: {
        currentView: function() {
            return this.state.currentView;
        }
    },
    components: {
        VolumeDiscountBranch,
        Details,
        Dashboard
    },
    watch: {
        id: function (){
            this.load();
        }
    }
}

</script>