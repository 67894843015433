<template>
    <div>
        <div class="col-sm-12 col-md-6 dashboard_md_text">
            <Metrics :data='data' />
        </div>
        <div class="col-sm-12 col-md-6">
            <div><Chart v-bind:results="this.chartData" /></div>
        </div>
        <div class='col-sm-12'>
            <People :people='data.people' />
        </div>
        <div class='col-sm-12'>
            <Communications :crmTasks='data.crm_tasks' />
        </div>
    </div>
</template>

<script>
import Metrics from './Metrics';
import Chart from '@/components/utils/Chart';
import People from './People';
import Communications from './Communications';

export default {
    name: 'Dashboard',
    props: ['data'],
    components: {
        Metrics,
        Chart,
        People,
        Communications
    },
    computed: {
        chartData: function() {
            return { chartData: this.data.chartData };
        },
    }
}
</script>