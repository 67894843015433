<template>
    <div class="object_detail">
        <label class="object_detail">{{ field.label }}</label>
        <div class="form-control">{{ val }}</div>
    </div>
</template>

<script>
    export default {
        name: "DetailsStatic",
        props: ["field", "val"]
    }
</script>

<style scoped>
    .form-control {
        white-space: pre-wrap;
        height: auto;
    }
</style>