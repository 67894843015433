<template>
  <div>
    <input type=button class="cdi_button btn btn-default" value="New Branch Volume Discount" @click="showForm = true">
    <form>
      <div class="panel panel-default spacing_top" v-show="showForm">
        <div class="panel-heading"></div>
        <div class="row panel-body">
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Product Type</label>
            <div>
              <input type="checkbox" v-model="selectAll" @change="toggleSelectAll"> Select All
            </div>
            <div v-for="[curbTypeId, curbType] in itemList" :key="curbTypeId">
              <input type="checkbox" :value="curbTypeId" v-model="formFields.selectedCurbTypes"> {{ curbType }}
            </div>
          </div>
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Quantity Required</label>
            <input type="text" v-model="formFields.quantity.val" class="form-control">
          </div>
          <div class="col-md-3 col-sm-4 col-xs-6 form-group">
            <label>Discount %</label>
            <input type="text" v-model="formFields.discount.val" class="form-control" placeholder='8'>
          </div>
          <div class="col-xs-12">
            <input class="cdi_button btn btn-default" type="button" value="Save Volume Discount" @click="saveDiscount()">
            <input type="button" value="Cancel" class="cdi_button btn btn-default" @click="clearForm()">
            <input type="hidden" name="branch_id" v-model="branchId">
          </div>
        </div>
      </div>
    </form>
    <div class="results">
      <table class='cdi_button table'>
        <thead>
        <tr>
          <th></th>
          <th>Description</th>
          <th>Quantity Required</th>
          <th>Discount %</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="volDiscount in data.volume_discounts" v-bind:key="volDiscount.branch_volume_discount_id">
          <td style="min-width: 100px;">
            <span class="glyphicon glyphicon-trash cdi_link" @click="deleteDiscountConfirm(volDiscount.branch_volume_discount_id)"></span>
          </td>
          <td>{{ volDiscount.description }}</td>
          <td>{{ volDiscount.quantity }}</td>
          <td>{{ volDiscount.discount }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ConfirmBox
        v-if="confirmBox"
        :heading="'Delete Volume Discount?'"
        :body="'Would you like to delete this Volume Discount?'"
        @close="deleteDiscount"
    />
  </div>
</template>

<script>
import curbTypeCache from  '@/cache/curbType.cache.js';
import {store} from "@/store/BusinessObject.store";
import helpers from "@/store/helpers";
import ConfirmBox from '@/components/utils/ConfirmBox';
export default {
  name: "VolumeDiscountBranch",
  components: {
    ConfirmBox,
  },
  data() {
    return {
      state: store.state,
      showForm: false,
      formFields: {
        selectedCurbTypes: [],
        quantity: { val: '', qsField: '[quantity]' },
        discount: { val: '', qsField: '[discount]' },
      },
      selectAll: false,
      confirmBox: false,
      deleteID: 0,
      cache: {
        curbTypeCache
      }
    }
  },
  props: ['data', 'branch_id'],
  methods: {
    clearForm: function () {
      this.showForm = false;
      this.formFields.quantity.val = '';
      this.formFields.discount.val = '';
      this.formFields.selectedCurbTypes = [];
      this.selectAll = false;
    },
    saveDiscount: function() {
      let selectedCurbType = this.formFields.selectedCurbTypes.join(',');
      let formFields = {
        ...this.formFields,
        curbTypeID: { val: selectedCurbType, qsField: '[curb_type_id]' },
        branch_id: { val: this.branch_id, qsField: '[branch_id]' }
      };
      store.api('save_volume_discount', helpers.getQSPairs(formFields), 'object.data')
          .then(() => this.clearForm());
    },
    deleteDiscountConfirm: function(volDiscountID) {
      this.deleteID = volDiscountID;
      this.confirmBox = true;
    },
    deleteDiscount: function(confirm) {
      if (confirm){
        store.api('delete_volume_discount', {'params[branch_volume_discount_id]': this.deleteID}, 'object.data');
      }
      this.confirmBox = false;
      this.deleteID = 0;
    },
    branchId: function (){
      return this.props.id;
    },
    toggleSelectAll: function() {
      if (this.selectAll) {
        this.formFields.selectedCurbTypes = this.itemList.map(item => item[0]);
      } else {
        this.formFields.selectedCurbTypes = [];
      }
    },
  },
  computed: {
    itemList: function() {
      let itemList = this.cache.curbTypeCache.CURB_TYPES.filter(item => item[0] != 10);
      itemList.push([10, 'Knockdowns']);

      return itemList;
    },
  }
}
</script>

<style scoped>
</style>
