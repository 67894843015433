<template>
    <div>
        <h2>People</h2>
        <div class="table-responsive">
            <table class='table'>
                <thead>
                <tr>
                    <th>Name</th>
                    <th class='number'>Sales</th>
                    <th class='number'>Quotes</th>
                    <th>Type</th>
                    <th>Phone</th>
                    <th>Title</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='person in people' :key='person.user_id' >
                    <td><router-link :to="`/user/${ person.user_id }`">{{ person.first_name }} {{ person.last_name }}</router-link></td>
                    <td class="number">${{ person.sales }}</td>
                    <td class="number">${{ person.quotes }}</td>
                    <td>{{ person.user_type }}</td>
                    <td>{{ person.phone }}</td>
                    <td>{{ person.title }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: 'People',
    props: ['people']
}

</script>