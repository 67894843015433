<template>

    <div>
        <div class="hide_overflow margin_bottom">
            <a target="_blank" :href="mapUrl">
                <span class="glyphicon glyphicon-map-marker"></span>
                <span>{{ data.city }}, {{ data.state }}</span>
            </a>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <label>Sales</label>
            </div>
            <div class="col-xs-4 number">
                <router-link :to='salesRptUrl'>${{ parseInt(data.sales_ytd).toLocaleString('en') }}</router-link>
            </div>
            <div :class="`col-xs-4 number ${salesDiffClass}`" v-show='data.sales_diff'>{{ data.sales_diff }}%</div>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <label>Quotes</label>
            </div>
            <div class="col-xs-4 number">
                <router-link :to='quoteRptUrl'>${{ parseInt(data.quotes_ytd).toLocaleString('en') }}</router-link>
            </div>
            <div :class="`col-xs-4 number ${quotesDiffClass}`" v-show='data.quotes_diff'>{{ data.quotes_diff }}%</div>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <label>Customers</label>
            </div>
            <div class="col-xs-4 number">
                <router-link :to='customerRptUrl' >{{ data.customer_count }}</router-link>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4">
                <label>Prospects</label>
            </div>
            <div class="col-xs-4 number">
                <router-link :to='prospectRptUrl' >{{ data.prospect_count }}</router-link>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'Metrics',
    props: ['data'],
    computed: {
        mapUrl: function() {
            const locationString = this.data.address + ' ' + this.data.city + ', ' + this.data.state;
            const queryString = encodeURIComponent(locationString);
            
            return `https://www.google.com/maps/search/?api=1&query=${queryString}`;
        },
        salesDiffClass: function() {
            return (parseInt(this.data.sales_ytd_last_year) > parseInt(this.data.sales_ytd)) ? 'text-danger' : 'text-success';
        },
        quotesDiffClass: function() {
            return (parseInt(this.data.quotes_ytd_last_year) > parseInt(this.data.quotes_ytd)) ? 'text-danger' : 'text-success';
        },
        salesRptUrl: function() {
            const start_date = encodeURIComponent('01/01/' + new Date().getFullYear());
            const end_date = encodeURIComponent(new Date().toLocaleDateString());
            const branch_name = encodeURIComponent(this.data.branch_name);

            return `/rpt/sales?actual_ship_start=${start_date}&actual_ship_end=${end_date}&branch_search=${branch_name}&branch_search_id=${this.data.branch_id}`
        },
        quoteRptUrl: function() {
            const start_date = encodeURIComponent('01/01/' + new Date().getFullYear());
            const end_date = encodeURIComponent(new Date().toLocaleDateString());
            const branch_name = encodeURIComponent(this.data.branch_name);

            return `/rpt/quote_rpt?quote_start=${start_date}&quote_end=${end_date}&branch_search=${branch_name}&branch_search_id=${this.data.branch_id}`;
        },
        customerRptUrl: function() {
            return `/rpt/user_rpt?company_search=${this.data.company_name}&company_search_id=${this.data.company_id}&user_type_search=1&branch_search=${this.data.branch_name}&branch_search_id=${this.data.branch_id}`;
        },
        prospectRptUrl: function() {
            return `/rpt/user_rpt?company_search=${this.data.company_name}&company_search_id=${this.data.company_id}&user_type_search=9&branch_search=${this.data.branch_name}&branch_search_id=${this.data.branch_id}`;
        }
    }
}

</script>