<template>
    <div>
        <h2>Communications</h2>
        <div class="table-responsive">
            <table class='table'>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Person</th>
                    <th>Type</th>
                    <th>Rep</th>
                    <th>Note</th>
                    <th>Done</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='task in crmTasks' :key='task.crm_task_id'>
                    <td>{{ task.date }}</td>
                    <td>{{ task.distributor }}</td>
                    <td>{{ task.type }}</td>
                    <td>{{ task.assigned_to }}</td>
                    <td>{{ task.note }}</td>
                    <td><input type="checkbox" disabled :checked='task.completed'> </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Communications',
    props: ['crmTasks']
}

</script>